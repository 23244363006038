export default {
  "header": "lN",
  "hidden": "lv",
  "header-inline": "lo",
  "header-inline__search-bar": "ln",
  "header-bottom": "li",
  "margin-top": "lA",
  "header-bottom-loading": "lY",
  "header-bottom-loading-state": "lh",
  "actions__filters": "lf",
  "actions__filters__toggle-btn": "lK",
  "tabs": "lG",
  "header-inline__filters": "lr",
  "filter-ml": "lb",
  "filter-clear": "ly"
};
