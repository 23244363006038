/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

export default class ClientsTableItem extends Component {
  InstructionalTooltip = InstructionalTooltip;

  @service segment;

  get hasReminders() {
    return this.args.item.hasReminders;
  }

  @action
  handleRemindersTooltipClose() {
    this.segment.track('client-list_discovery-tooltip_closed');
    this.args.dismissRemindersTooltip();
  }

  @action
  handleQuickActionsToggleMenu(showMenu) {
    if (showMenu) {
      this.args.dismissRemindersTooltip();
    }
  }
}
