export default {
  "wrapper": "wk",
  "header": "wJ",
  "no-separator": "ws",
  "tabs": "wF",
  "tab": "wg body-1 overlay",
  "selected": "wD",
  "organization-list": "wV",
  "content": "wL",
  "empty-state": "wT",
  "illustration": "wz",
  "body": "wa body-1",
  "cta": "wH btn btn--tertiary"
};
