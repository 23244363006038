export default {
  "sub-menu": "NA",
  "collapsed": "NY",
  "collapse-left": "Nh",
  "responsive": "Nf",
  "expanded": "NK",
  "no-slide": "NG",
  "visible": "Nr",
  "hidden": "Nb",
  "group": "Ny",
  "promo-box": "NU"
};
