export default {
  "aside": "ZG",
  "aside-section": "Zr",
  "aside-content": "Zb",
  "aside-footer": "Zy",
  "aside-title": "ZU caption-bold",
  "accountant-upsell": "Zj",
  "file-status": "ZW",
  "file-status-icon": "Zu",
  "file-status-text": "RS",
  "file-status-label": "Rc body-1",
  "file-status-description": "Rq body-2"
};
