/* import __COLOCATED_TEMPLATE__ from './promotion-recommendation.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { billerBaseURL, billerV3Namespace, billerV4Namespace } from 'qonto/constants/hosts';
import { getPromotionRecommendationsConfig, PROMOTION_TYPES } from 'qonto/constants/promotion';
import { SUBSCRIPTION_PRODUCT_TYPES } from 'qonto/constants/subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class PromotionRecommendationComponent extends Component {
  @service abilities;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;
  @service router;
  @service subscriptionManager;
  @service flowLinkManager;
  @service modals;
  @tracked recommendationInfo = null;

  constructor() {
    super(...arguments);
    this.getPromotionRecommendation();
  }

  get recommendation() {
    if (
      this.shouldSkip ||
      this.fetchRecommendationTask.last?.isError ||
      this.fetchRecommendationTask.isRunning
    ) {
      return null;
    }

    return this.calculateRecommendation;
  }

  async getPromotionRecommendation() {
    if (this.shouldSkip) {
      return;
    }

    if (this.variation) {
      this.recommendationInfo = null;
      let isMandatesEmpty = await this.isMandatesEmpty();
      if (isMandatesEmpty) {
        return;
      }
      let featureNames = Object.keys(this.routeConfig);
      this.recommendationInfo = await this.fetchFirstSuccessfulRecommendation(featureNames);
    } else {
      let featureName = Object.keys(this.routeConfig)[0];
      await this.fetchRecommendationTask
        .perform({ featureName })
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
            this.sentry.captureException(error);
          }
        });
    }

    this.trackShownEvent();
  }

  trackShownEvent() {
    let recommendation = this.recommendation;
    if (!recommendation) {
      return;
    }

    let { trialInfo, featureConfig } = recommendation;
    let promotionType = this.getPromotionType(trialInfo);

    this.segment.track('promotion-card_shown', {
      'promotion-card_type': promotionType,
      'promotion-card_feature': this.variation
        ? featureConfig.tracking.featureName
        : featureConfig.trackingFeatureName,
      ...(this.variation && featureConfig.tracking?.upsell_type
        ? { upsell_type: featureConfig.tracking.upsell_type }
        : null),
    });
  }

  fetchRecommendationTask = restartableTask(async ({ featureName }) => {
    // TODO modularPricing cleanup
    if (!this.organizationManager.organization.hasModularPricing) {
      return await this.networkManager.request(
        `${billerBaseURL}/${billerV3Namespace}/promotion_recommendation?feature_name=${featureName}`
      );
    }

    return await this.networkManager.request(
      `${billerBaseURL}/${billerV4Namespace}/subscriptions/upgrade_recommendation?feature_code=${featureName}`
    );
  });

  fetchMandatesTask = restartableTask(async () => {
    return await this.store.query('mandate', {
      organization_id: this.organizationManager.organization.id,
    });
  });

  get shouldSkip() {
    if (
      !this.abilities.can('update subscription') ||
      !this.args.activeSubMenuItem ||
      !this.routeConfig
    ) {
      return true;
    }

    return false;
  }

  get routeConfig() {
    let isModularPricing = this.organizationManager.organization.hasModularPricing;

    let subMenuId = this.args.activeSubMenuItem.id;
    return getPromotionRecommendationsConfig(this.intl, isModularPricing)[subMenuId];
  }

  getPromotionType(trialInfo) {
    return trialInfo ? PROMOTION_TYPES.TRY : PROMOTION_TYPES.UPSELL;
  }

  get calculateRecommendation() {
    let product = null;
    let recurrence = null;

    // TODO modularPricing cleanup
    if (!this.organizationManager.organization.hasModularPricing) {
      let { price_plan: pricePlan } = this.fetchRecommendationTask.last?.value || {};
      product = pricePlan;
    } else {
      let {
        recommended_product: recommendedProduct,
        recommended_recurrence: recommendedRecurrence,
      } = this.fetchRecommendationTask.last?.value || {};
      product = recommendedProduct;
      recurrence = recommendedRecurrence;
    }

    if (!product?.id) {
      return null;
    }

    if (
      this.variation &&
      (!this.recommendationInfo || !this.routeConfig[this.recommendationInfo.featureName])
    ) {
      return null;
    }

    let featureConfig = this.variation
      ? this.routeConfig[this.recommendationInfo.featureName]
      : Object.values(this.routeConfig)[0];

    let trialInfo;

    // TODO modularPricing cleanup
    if (!this.organizationManager.organization.hasModularPricing) {
      trialInfo = featureConfig[PROMOTION_TYPES.TRY]
        ? this.subscriptionManager.currentSubscription?.availableTrials?.find(
            ({ pricePlanId }) => pricePlanId === product.id
          )
        : false;

      return {
        product,
        featureConfig: featureConfig[this.getPromotionType(trialInfo)],
        trialInfo,
      };
    }

    if (product.type === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
      trialInfo = featureConfig[PROMOTION_TYPES.TRY]
        ? this.subscriptionManager.currentSubscription?.availableTrials?.find(
            ({ productId }) => productId === product.id
          )
        : false;
    } else {
      // Trials are not supported for addons
      trialInfo = false;
    }

    return {
      product,
      featureConfig: featureConfig[this.getPromotionType(trialInfo)],
      recurrence,
      trialInfo,
    };
  }

  @action
  onCtaClick(recommendation) {
    let { featureConfig, product, recurrence, trialInfo } = recommendation;
    let featureName = this.variation
      ? featureConfig.tracking.featureName
      : featureConfig.trackingFeatureName;

    this.segment.track(`${featureName}_cta_clicked`, {
      cta_type: this.getPromotionType(trialInfo),
      origin: 'dual_nav',
      ...(this.variation && featureConfig.tracking?.upsell_type
        ? { upsell_type: featureConfig.tracking.upsell_type }
        : null),
    });

    if (trialInfo) {
      this.modals.open('discover/trial/confirm', {
        isFullScreenModal: true,
        trialInfo,
      });
      return;
    }

    let { code, type } = product;

    // TODO modularPricing cleanup
    if (!this.organizationManager.organization.hasModularPricing) {
      return this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          plan: code,
          recurrence: this.subscriptionManager.currentSubscription?.recurrence,
        },
      });
    }

    this.subscriptionManager.transitionToSubscriptionOrAddonChange({
      code,
      recurrence,
      type,
    });
  }

  get variation() {
    return variation('feature--boolean-soft-quota-promotion');
  }

  async fetchFirstSuccessfulRecommendation(featureNames) {
    for (let featureName of featureNames) {
      let result = await this.fetchRecommendationTask
        .perform({ featureName })
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.httpStatus !== 404) {
            if (errorInfo.shouldSendToSentry) {
              this.sentry.captureException(error);
            }
          }
          return null;
        });

      if (result) {
        return { featureName };
      }
    }
    return null;
  }

  async isMandatesEmpty() {
    if (this.args.activeSubMenuItem.id !== 'outgoing-direct-debits') {
      return false;
    }

    let response = await this.fetchMandatesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return response ? response.meta.total_count === 0 : true;
  }
}
