export default {
  "content": "eT",
  "balance": "ez",
  "balance-failed": "ea",
  "bank-infos": "eH",
  "bank-name": "eO",
  "bank-identifier": "em",
  "bank-iban": "ep",
  "footer-container": "ex",
  "links": "ew",
  "links--start-verification": "eN",
  "link": "ev",
  "start-verification-link": "eo",
  "last-sync": "en"
};
