/* import __COLOCATED_TEMPLATE__ from './sub-menu.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class DualNavSubMenuComponent extends Component {
  @service abilities;
  @service router;
  @service intl;
  @service invoiceSubscriptionManager;
  @service flowLinkManager;

  constructor() {
    super(...arguments);

    let { label, groups, state } = this.args;

    assert('<DualNav::SubMenu /> must have @label', label);
    assert('<DualNav::SubMenu /> must have @groups', groups);
    assert('<DualNav::SubMenu /> must have @groups.length > 0', groups.length);

    if (this.isVisible && state !== DUAL_NAV_STATES.COLLAPSED) {
      document.body.classList.remove('prevent-nav-animation');
    }
  }

  get isVisible() {
    let { activeMenuItemId, currentMenuItemId, hoveredMenuItemId, state } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE) {
      return hoveredMenuItemId === currentMenuItemId;
    }
    return (hoveredMenuItemId ?? activeMenuItemId) === currentMenuItemId;
  }

  get shouldDisplayAccountAggregationPromoBox() {
    let { state, hoveredMenuItemId } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'business-account') {
      return false;
    }

    return (
      this.abilities.can('import external-account') &&
      this.router.currentRouteName === 'accounts.index'
    );
  }

  get shouldShowRecurringInvoicesPromoBox() {
    return (
      variation('feature--boolean-recurring-invoices-with-sdd') &&
      this.invoiceSubscriptionManager.shouldShowPromoBox &&
      this.router.currentRouteName === 'invoice-subscriptions.index'
    );
  }

  get shouldDisplayAccountReceivableOnboardingPromoBox() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      (this.router.currentRouteName === 'receivable-invoices.index' ||
        this.router.currentRouteName === 'invoice-subscriptions.index')
    );
  }

  get shouldDisplayPayLaterPromoBox() {
    let { state, hoveredMenuItemId, activeSubMenuItem } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'financing') {
      return false;
    }

    return (
      variation('feature--boolean-pay-later-promotion-installments') &&
      activeSubMenuItem?.id === 'pay-later'
    );
  }

  get shouldShowSupplierInvoiceArchivePromoBox() {
    let { state, hoveredMenuItemId } = this.args;

    return !(state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'invoices');
  }

  @action
  recurringInvoicePromoBadgeClick() {
    this.flowLinkManager.transitionTo({
      name: 'sdd-activation',
      stepId: 'intro',
      queryParams: { origin: 'invoice-subscriptions.index' },
    });
    safeLocalStorage.setItem(PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }

  @action
  onAnimationEnd() {
    document.body.classList.remove('prevent-nav-animation');
  }
}
