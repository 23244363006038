export default {
  "row": "ka",
  "active": "kH",
  "status": "kO",
  "align-right": "km",
  "animated": "kp",
  "fadein-item": "kx",
  "cell": "kw body-2",
  "cell-content": "kN",
  "amount": "kv body-1",
  "subtitle": "ko caption",
  "no-padding": "kn",
  "note": "ki"
};
