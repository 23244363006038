export default {
  "header-cell": "Ch",
  "header-content": "Cf caption-bold",
  "active": "CK",
  "hidden": "CG",
  "empty": "Cr",
  "align-right": "Cb",
  "no-padding": "Cy",
  "request-type": "CU",
  "amount": "Cj",
  "approval": "CW"
};
