/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';

import {
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTION_STATUS,
  CONNECTION_EXPIRATION_STATUS,
} from 'qonto/constants/connect';
import { Avatar } from 'qonto/react/components/connections/avatar';

const DISCLAIMERS = new Map([
  [
    CONNECTION_EXPIRATION_STATUS.ACTIVE,
    {
      level: 'info',
      translationKey: 'settings.connections.sidebar.disclaimer.bank-expiry-date',
      translationParam: 'expirationDate',
    },
  ],
  [
    BANK_CONNECTION_STATUS.SYNCHRONIZED,
    {
      level: 'info',
      translationKey: 'settings.connections.sidebar.disclaimer.bank-expiry-date',
      translationParam: 'expirationDate',
    },
  ],
  [
    CONNECTION_EXPIRATION_STATUS.EXPIRING,
    {
      level: 'warning',
      translationKey: 'settings.connections.sidebar.alert',
      translationParam: 'expirationDate',
    },
  ],
  [
    CONNECTION_EXPIRATION_STATUS.EXPIRED,
    {
      level: 'warning',
      translationKey: 'settings.connections.sidebar.expired',
      translationParam: 'expirationDate',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PROVIDER_ISSUE,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.technical-issue.disclaimer',
    },
  ],
  [
    BANK_CONNECTION_STATUS.SUSPENDED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.fin-api-fixable.disclaimer',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PASSWORD_EXPIRED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.password-expired.disclaimer',
      translationParam: 'bankName',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PASSWORD_UPDATED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.wrong-pass.disclaimer',
      translationParam: 'bankName',
    },
  ],
  [
    BANK_CONNECTION_STATUS.ACTION_REQUIRED_ON_BANK,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.action-needed.disclaimer',
      translationParam: 'bankName',
    },
  ],
]);

export default class ConnectionsSidebarHeaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;

  Avatar = Avatar;

  get showDisclaimer() {
    return [
      BANK_CONNECTION_STATUS.SYNCHRONIZED,
      CONNECTION_EXPIRATION_STATUS.ACTIVE,
      CONNECTION_EXPIRATION_STATUS.EXPIRING,
      CONNECTION_EXPIRATION_STATUS.EXPIRED,
      ...BANK_CONNECTION_FAILURE_STATUSES,
    ].includes(this.args.connectionStatus);
  }

  get disclaimerLevel() {
    return DISCLAIMERS.get(this.args.connectionStatus)?.level;
  }

  get disclaimerMessage() {
    let { connectionStatus, connectionExpiresAt, connectionName } = this.args;
    let disclaimer = DISCLAIMERS.get(connectionStatus);
    let translationKey = disclaimer?.translationKey;
    let translationParam = disclaimer?.translationParam;

    if (!translationKey) return;

    let translationParams;

    switch (translationParam) {
      case 'expirationDate':
        translationParams = {
          expirationDate: dateToken({
            date: connectionExpiresAt,
            locale: this.intl.locale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          }),
        };
        break;

      case 'bankName':
        translationParams = { bankName: connectionName };
        break;
    }

    return this.intl.t(translationKey, translationParams);
  }
}
