export default {
  "row": "Og",
  "cell": "OD body-2",
  "empty": "OV",
  "cell-content": "OL",
  "cell-content--amount": "OT body-1",
  "icon": "Oz",
  "description": "Oa",
  "tertiary": "OH",
  "details": "OO"
};
