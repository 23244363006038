export default {
  "container": "lJ",
  "container-metal": "ls",
  "pickers": "lF",
  "picker-wrapper": "lg",
  "picker": "lD",
  "checked": "lV",
  "lilac": "lL",
  "black": "lT",
  "mineral-gray": "lz",
  "sand-gold": "la",
  "graphite-black": "lH"
};
