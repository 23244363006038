export default {
  "card-sidebar-header": "Mr",
  "content": "Mb",
  "card-image-container": "My",
  "card-image": "MU",
  "legacy": "Mj",
  "type": "MW",
  "type-container": "Mu",
  "type-close": "PS",
  "type-status-awaiting": "Pc",
  "type-status-live": "Pq",
  "type-status-paused": "PZ",
  "type-status-ghost": "PR",
  "actions-panel": "PQ",
  "actions-panel-header": "Pe",
  "action-btn": "PB",
  "disclaimer": "PE",
  "infobox": "Pd",
  "btn-panel": "PI",
  "required-action-btn": "Pt",
  "kyc-disclaimer": "PM"
};
