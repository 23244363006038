export default {
  "wrapper": "Om",
  "header": "Op",
  "status": "Ox",
  "close-button": "Ow",
  "slug": "ON",
  "category": "Ov",
  "category-title": "Oo",
  "activity-tag-select": "On",
  "footer": "Oi"
};
