/* import __COLOCATED_TEMPLATE__ from './save.hbs'; */
import { set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask, task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import isFunction from 'qonto/utils/is-function';

export default class CardSaveProvider extends Component {
  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service sensitiveActions;
  @service sentry;
  @service store;
  @service organizationManager;

  saveCardTask = dropTask(async (context, onSuccess) => {
    try {
      let { card } = context;

      card.addIdempotencyHeader();

      let isQcp = await this.cardsManager.getIsQcpCardTask.perform(card.cardLevel);
      card.isQcp = isQcp;

      let data;

      if (isQcp) {
        data =
          card.isPhysical && card.pin
            ? await this.cardsManager.buildPayloadWithEncryptedPin(card)
            : this.cardsManager.buildPayloadWithoutPin(card);
      }

      await this.sensitiveActions.runTask.perform(this._saveTask, {
        card,
        context,
        data,
        onSuccess,
      });
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  _saveTask = task(async ({ card, context, data, onSuccess }) => {
    try {
      if (card.isQcp) {
        let response = await this.cardsManager.postCardWithEncryptedPinTask.perform(data);
        this.store.pushPayload('card', response);
        // hacks because the card is not saved through ember-data
        // the card model in the context and in the response are not reconciled
        card.isQcpSaved = true;
        set(context, 'cardId', response.card.id);
      } else {
        await card.save();
      }

      card.removeIdempotencyHeader();

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      let errors = error?.errors;
      if (hasMFAError(errors)) {
        throw error;
      }

      // errors about card saving must be catched here, because they are not thrown above sensitiveActions.runTask.perform()
      if (errors) {
        errors.forEach(errorItem => {
          switch (errorItem.code) {
            case 'too_many_digital_cards_for_holder_recently':
            case 'too_many_digital_cards_for_holder':
              this.toastFlashMessages.toastError(
                this.intl.t('toasts.errors.too-many-digital-cards')
              );
              break;
            default:
              this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          }
        });
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
