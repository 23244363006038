export default {
  "envelope": "av",
  "email": "ao",
  "logo": "an",
  "subject": "ai",
  "message": "aA",
  "payment-details": "aY",
  "contact": "ah",
  "detail": "af",
  "cta": "aK"
};
