export default {
  "header": "qM",
  "header-type": "qP",
  "header-infos": "ql",
  "title": "qX",
  "close": "qC",
  "close-icon": "qk",
  "subtitle": "qJ",
  "date": "qs",
  "container": "qF",
  "picto": "qg",
  "icon": "qD",
  "general": "qV",
  "amount": "qL",
  "infobox": "qT"
};
