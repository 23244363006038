import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FormField from '@qonto/ui-kit/components/form-fields/form-field';
import PowerSelectCustomsTrigger from '@qonto/ui-kit/components/power-select-customs/trigger';
import { t } from 'ember-intl';
import PowerSelect from 'ember-power-select/components/power-select';
import { OPERATION_TYPES, VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './operation-selector.strict-module.css';
export default class ApprovalWorkflowFromStepSelectorsOperationSelector extends Component {
    @service
    intl;
    OPERATIONS_OPTIONS = [
        {
            value: this.intl.t('approval-workflows.form.steps.operation.equal-any'),
            key: OPERATION_TYPES.ONE_OF
        },
        {
            value: this.intl.t('approval-workflows.form.steps.operation.equal-all'),
            key: OPERATION_TYPES.ALL_OF
        }
    ];
    VALIDATION_ERROR_KEYS = {
        [VALIDATION_ERRORS.STEP_OPERATION_EMPTY]: this.intl.t('approval-workflows.errors.steps.operation.empty')
    };
    get selected() {
        let [selected1] = this.OPERATIONS_OPTIONS.filter((operation1)=>operation1.key === this.args.selectedOperation);
        return selected1;
    }
    get errorMessage() {
        if (this.args.operationErrors) {
            return this.VALIDATION_ERROR_KEYS[this.args.operationErrors[0].message];
        }
        return null;
    }
    static{
        template(`
    <FormField ...attributes @message={{this.errorMessage}} @messageLevel='error'>
      <:field as |field|>
        <PowerSelect
          @ariaDescribedBy={{field.messageId}}
          @ariaLabelledBy={{field.labelId}}
          @triggerComponent={{component PowerSelectCustomsTrigger selected=this.selected.value}}
          @selected={{this.selected}}
          @dropdownClass='power-select-custom fit-content'
          @options={{this.OPERATIONS_OPTIONS}}
          @onChange={{@handleOperationTypeChange}}
          @triggerClass='power-select-customs-trigger block {{if
            this.errorMessage
            "error has-danger"
          }}'
          @placeholder={{t 'approval-workflows.form.placeholder.operation'}}
          @onBlur={{@validateOperation}}
          class={{styles.operation-select-trigger}}
          data-test-approval-workflow-form-operation-type-selector
          as |operation|
        >
          <span class='body-1' data-test-select-operation={{operation.key}}>
            {{operation.value}}
          </span>
        </PowerSelect>
      </:field>
    </FormField>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
