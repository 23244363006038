export default {
  "card-container": "VB",
  "compact": "VE",
  "card-header": "Vd mb-16",
  "application-logo": "VI",
  "application-name": "Vt",
  "application-bic": "VM caption-2",
  "application-title": "VP title-4 mb-8",
  "application-categories": "Vl",
  "application-card-tag": "VX tag",
  "short-description": "VC body-2"
};
