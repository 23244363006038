export default {
  "header-cell": "pc",
  "empty": "pq",
  "right": "pZ",
  "debitor": "pR",
  "date": "pQ",
  "status": "pe",
  "amount": "pB",
  "header-amount": "pE",
  "header-content": "pd caption-bold",
  "active": "pI"
};
