export default {
  "card": "tg",
  "avatar": "tD",
  "content": "tV",
  "content-top": "tL",
  "fullname": "tT",
  "representative": "tz",
  "icon": "ta",
  "type": "tH",
  "menu-container": "tO",
  "dropdown-menu": "tm",
  "menu-item": "tp body-2",
  "delete": "tx"
};
