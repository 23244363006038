export default {
  "header-cell": "gk",
  "empty": "gJ",
  "emitter": "gs",
  "date": "gF",
  "amount": "gg",
  "status": "gD",
  "header-content": "gV caption-bold",
  "active": "gL"
};
