export default {
  "wrapper": "ph",
  "modal": "pf",
  "close-button": "pK",
  "content": "pG",
  "title": "pr title-1",
  "description": "pb body-2",
  "slides-container": "py",
  "slide": "pU"
};
