export default {
  "header-cell": "Jv",
  "border-top": "Jo",
  "header-content": "Jn caption-bold",
  "active": "Ji",
  "empty": "JA",
  "card": "JY",
  "card-holder": "Jh",
  "limit": "Jf",
  "align-right": "JK",
  "status": "JG",
  "hidden": "Jr",
  "no-padding": "Jb"
};
