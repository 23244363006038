/* import __COLOCATED_TEMPLATE__ from './international-out-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { INTERNATIONAL_OUT_PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/international-out/storage';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class DualNavInternationalOutPromoBoxComponent extends Component {
  @service flowLinkManager;
  @service segment;

  @action
  navigate() {
    this.flowLinkManager.transitionTo({
      name: 'international-out',
      stepId: 'quote',
      queryParams: {
        targetCurrency: 'EUR',
      },
    });

    safeLocalStorage.setItem(INTERNATIONAL_OUT_PROMO_BOX_LOCAL_STORAGE_KEY, Date.now());
  }
}
