export default {
  "row": "Cu",
  "active": "kS",
  "status": "kc",
  "align-right": "kq",
  "quick-actions": "kZ",
  "cell-approve": "kR",
  "account-select": "kQ",
  "hidden": "ke",
  "no-padding": "kB",
  "animated": "kE",
  "fadein-item": "kd",
  "cell": "kI body-2",
  "cell-content": "kt",
  "amount": "kM body-1",
  "subtitle": "kP caption"
};
