export default {
  "card": "tS",
  "row": "tc",
  "icon": "tq",
  "unrevokable": "tZ",
  "active": "tR",
  "kyc-pending": "tQ",
  "shareholder-info": "te",
  "name": "tB",
  "iban": "tE",
  "tooltip": "td",
  "shareholder-capital": "tI",
  "shareholder-capital-amount": "tt",
  "deposit-capital": "tM",
  "valid": "tP",
  "pending": "tl",
  "promised-capital-valid": "tX",
  "promised-capital": "tC"
};
