export default {
  "wrapper": "Qv",
  "core-information": "Qo",
  "iban": "Qn",
  "bic": "Qi",
  "full-line": "QA",
  "secondary-information": "QY",
  "title": "Qh",
  "rib": "Qf",
  "rib-copy": "QK",
  "flex-column": "QG",
  "label": "Qr body-1",
  "value": "Qb body-2",
  "right-spaced": "Qy",
  "name": "QU title-3",
  "disclaimer": "Qj"
};
