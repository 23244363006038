export default {
  "header-cell": "HM",
  "type": "HP",
  "request-date": "Hl",
  "amount": "HX",
  "quick-actions": "HC",
  "empty": "Hk",
  "header-content": "HJ caption-bold",
  "active": "Hs"
};
