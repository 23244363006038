/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ApprovalWorkflowsUpsell extends Component {
  @service intl;
  @service segment;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  BADGE_TYPE = BADGE_TYPE;

  onCtaClickTask = dropTask(async () => {
    await this.subscriptionManager.upgradeWithRecommendation('approval_workflows');
  });

  @action
  onCtaClick() {
    this.segment.track('approval-workflow_CTA_clicked', {
      cta_type: 'upsell',
    });
    this.onCtaClickTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      });
  }
}
