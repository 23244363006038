export default {
  "cell": "dF caption-bold",
  "empty": "dg",
  "transaction": "dD",
  "member": "dV",
  "method": "dL",
  "operation-date": "dT",
  "amount": "dz",
  "label": "da caption-bold",
  "active": "dH"
};
