export default {
  "card": "Ej",
  "header": "EW",
  "budget-caption": "Eu caption",
  "amount-spent-section": "dS",
  "amount-spent-icon-container": "dc",
  "amount-spent-title": "dq title-3",
  "amount-budgeted-section": "dZ",
  "amount-budgeted-title": "dR title-3"
};
