export default {
  "tile-layout": "et",
  "focus": "eM",
  "no-shadow": "eP",
  "tile-layout--start-verification": "el",
  "header": "eX",
  "headings": "eC",
  "management": "ek",
  "logo": "eJ",
  "title": "es title-4",
  "name": "eF title-4",
  "loading": "eg",
  "menu-item": "eD body-2",
  "close-account": "eV",
  "content": "eL"
};
