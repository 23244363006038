export default {
  "wrapper": "Nk",
  "active": "NJ",
  "expanded": "Ns",
  "fade-in": "NF",
  "hidden": "Ng",
  "visible": "ND",
  "item-list": "NV",
  "item-list-slide-down": "NL",
  "dropdown-trigger": "NT overlay",
  "settings-dropdown-slide-down": "Nz",
  "responsive": "Na",
  "is-selected": "NH",
  "is-active": "NO",
  "dropdown": "Nm"
};
