export default {
  "row": "Jy",
  "active": "JU",
  "animated": "Jj",
  "fadein-item": "JW",
  "cell": "Ju body-2",
  "cell-content": "sS",
  "no-padding": "sc",
  "hidden": "sq",
  "disabled": "sZ",
  "not-disabled": "sR",
  "card-icon": "sQ",
  "card-infos": "se",
  "card-digits": "sB",
  "card-digits-warning": "sE",
  "status-puce": "sd",
  "live": "sI",
  "waiting-funds": "st",
  "ghost": "sM",
  "payment-limit-bar": "sP",
  "align-right": "sl"
};
