export default {
  "sidebar-header": "mS",
  "sidebar-header__top": "mc",
  "sidebar-header__close-button": "mq",
  "close-button": "mZ",
  "sidebar-header__body": "mR",
  "sidebar-header__content": "mQ",
  "sidebar-header__date": "me",
  "infobox": "mB",
  "infobox--link": "mE",
  "ghost": "md",
  "fail": "mI",
  "success": "mt"
};
