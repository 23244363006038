export default {
  "wrapper": "aZ",
  "label": "aR",
  "input": "aQ",
  "icon-trash": "ae",
  "hidden": "aB",
  "icon-color": "aE",
  "icon-edit": "ad",
  "icon-edit-save": "aI",
  "button-container": "at"
};
