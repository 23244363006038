export default {
  "panel-container": "Io",
  "selector-container": "In",
  "container-title": "Ii",
  "panel-title": "IA",
  "panel-title-text": "IY",
  "panel-icon": "Ih",
  "icon": "If",
  "animate-in": "IK",
  "panel-content": "IG",
  "error-message": "Ir"
};
