export default {
  "container": "XD",
  "content": "XV",
  "table": "XL",
  "table-body": "XT",
  "title": "Xz",
  "title-with-badge": "Xa",
  "metal-card-title": "XH",
  "subtitle": "XO",
  "card-image": "Xm",
  "button-container": "Xp"
};
