export default {
  "sidebar": "TU",
  "scroll": "Tj",
  "header": "TW",
  "close": "Tu",
  "vat": "zS",
  "labels": "zc",
  "actions": "zq",
  "buttons-container": "zZ"
};
