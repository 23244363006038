export default {
  "container": "lU",
  "is-loaded": "lj",
  "disabled": "lW",
  "image": "lu",
  "infos": "XS",
  "fully-masked": "Xc",
  "black": "Xq",
  "infos-type--virtual": "XZ",
  "infos-holder": "XR",
  "infos-pan": "XQ",
  "infos-pan-numbers": "Xe",
  "infos-exp-date": "XB",
  "infos-cvv": "XE",
  "infos-caption": "Xd",
  "copy-btn": "XI",
  "description-details": "Xt"
};
