export default {
  "container": "PP",
  "is-sidebar-content": "Pl",
  "no-sep": "PX",
  "details": "PC",
  "detail": "Pk",
  "name": "PJ",
  "content": "Ps",
  "content-text": "PF",
  "break-word": "Pg",
  "primary": "PD",
  "links-container": "PV",
  "infos-container": "PL",
  "infos-disclaimer": "PT",
  "upsell-container": "Pz"
};
