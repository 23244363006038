export default {
  "row": "ps body-1",
  "active": "pF",
  "cell": "pg body-2",
  "empty": "pD",
  "no-padding": "pV",
  "cell-content": "pL",
  "item-disabled": "pT",
  "item-amount": "pz body-1",
  "centered-cell": "pa",
  "debitor": "pH",
  "item-counterparty-icon": "pO mr-16",
  "avatar": "pm",
  "item-counterparty-name": "pp",
  "item-counterparty-reference": "px",
  "item-counterparty-name-status": "pw",
  "item-counterparty-name-text": "pN",
  "amount-on-hold": "pv",
  "icon-on-hold": "po"
};
