export default {
  "row": "Tc body-1",
  "active": "Tq",
  "cell": "TZ",
  "empty": "TR",
  "connection": "TQ",
  "connection-avatar": "Te",
  "connection-name": "TB",
  "member-name": "TE body-2",
  "member-role": "Td caption",
  "status": "TI",
  "failure": "Tt",
  "error": "TM",
  "hidden": "TP"
};
