export default {
  "row": "Ha body-1",
  "cell": "HH",
  "empty": "HO",
  "amount": "Hm",
  "review": "Hp",
  "cell-content": "Hx",
  "icon": "Hw",
  "name": "HN",
  "name-text": "Hv",
  "quick-actions": "Ho",
  "account-select": "Hn"
};
