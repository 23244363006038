export default {
  "header": "Ff",
  "result": "FK",
  "result-value": "FG",
  "result-value-key": "Fr",
  "result-value-icon": "Fb",
  "green": "Fy",
  "red": "FU",
  "result-value-description": "Fj",
  "result-title": "FW",
  "chevron": "Fu",
  "detail": "gS",
  "detail-title": "gc",
  "detail-value": "gq",
  "tooltip-wrapper": "gZ",
  "tooltip": "gR",
  "disclaimer": "gQ"
};
