/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import { DISCOVERABILITY_STORAGE_KEY } from 'qonto/constants/reminders-configuration';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class ClientsTable extends Component {
  @service segment;

  @tracked isRemindersTooltipDismissed = false;

  get shouldDisplayRemindersTooltip() {
    if (!variation('discovery--boolean-automated-reminders-tooltip')) {
      return false;
    }

    let storageValue = safeLocalStorage.getItem(DISCOVERABILITY_STORAGE_KEY);

    return storageValue !== 'true';
  }

  @action
  handleClick(itemId) {
    this.args.highlightItem(itemId);
    this.dismissRemindersTooltip();
  }

  @action
  dismissRemindersTooltip() {
    safeLocalStorage.setItem(DISCOVERABILITY_STORAGE_KEY, true);

    this.isRemindersTooltipDismissed = true;
  }
}
