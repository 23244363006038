export default {
  "budget-creation-form": "En",
  "period-label": "Ei",
  "periods": "EA",
  "yearly": "EY",
  "yearly-toggle": "Eh",
  "toggle-tooltip": "Ef",
  "tooltip": "EK",
  "year": "EG",
  "total-budget": "Er",
  "total-budget-value": "Eb",
  "divider": "Ey"
};
