export default {
  "wrapper": "QW",
  "title": "Qu",
  "button-icon": "eS",
  "download-button": "ec",
  "specification-list": "eq",
  "certified-details-wrapper": "eZ",
  "details-wrapper": "eR",
  "certified-specification-list": "eQ",
  "specification": "ee",
  "icon": "eB",
  "cross": "eE",
  "checkmark": "ed",
  "pit-link": "eI"
};
