export default {
  "wrapper": "xt",
  "badge-trial": "xM",
  "header": "xP",
  "list": "xl body-2",
  "main": "xX",
  "card": "xC",
  "container": "xk",
  "header-button": "xJ",
  "error-text": "xs",
  "mt-16": "xF"
};
