export default {
  "wrapper": "Xx",
  "content-wrapper": "Xw",
  "no-fee": "XN",
  "concierge": "Xv",
  "no-fee-item": "Xo",
  "concierge-item": "Xn",
  "no-fee-icon": "Xi",
  "lounge-icon": "XA",
  "insurance-conditions": "XY",
  "description": "Xh",
  "footer-detail": "Xf",
  "title": "XK",
  "footer-price": "XG",
  "footer-content": "Xr",
  "lounge-text": "Xb",
  "lounge": "Xy",
  "lounge-images": "XU",
  "img": "Xj",
  "footer-sticky-bar": "XW",
  "visible": "Xu",
  "content": "CS",
  "content-card": "Cc"
};
