export default {
  "chip": "Dy",
  "chip--hidden": "DU",
  "chip--collapsed": "Dj",
  "chip--selected": "DW",
  "chip--disabled": "Du",
  "wrapper": "VS",
  "content": "Vc body-1",
  "clear": "Vq",
  "clear-icon": "VZ"
};
