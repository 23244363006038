export default {
  "container": "MF",
  "label": "Mg",
  "amount-input": "MD",
  "error": "MV",
  "error-message": "ML",
  "title": "MT",
  "subtitle": "Mz",
  "submit-button": "Ma btn btn--primary btn--large"
};
