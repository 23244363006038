export default {
  "budget-edit-form": "Ie",
  "period-label": "IB",
  "periods": "IE",
  "yearly": "Id",
  "tooltips": "II",
  "year": "It",
  "total-budget": "IM",
  "total-budget-value": "IP",
  "divider": "Il",
  "buttons": "IX"
};
