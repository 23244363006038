export default {
  "attachment-viewer": "RE",
  "sidebar": "Rd",
  "attachment-viewer-details": "RI",
  "attachment-viewer-filename": "Rt body-2",
  "attachment-viewer-actions": "RM",
  "dropzone": "RP",
  "dropzone-visible": "Rl",
  "attachment-main": "RX"
};
