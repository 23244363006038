export default {
  "modal": "Ta",
  "features": "TH",
  "close-button": "TO",
  "header": "Tm title-2",
  "header-text": "Tp body-2",
  "feature": "Tx",
  "subheader": "Tw title-4",
  "text": "TN body-2",
  "cta": "Tv",
  "container-img": "To",
  "img": "Tn",
  "link": "Ti"
};
