/* import __COLOCATED_TEMPLATE__ from './approvals.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { OPERATION_TYPES } from 'qonto/constants/approval-workflow';
import { STATUS } from 'qonto/constants/approval-workflow-state';
import { ROLES } from 'qonto/constants/membership';

const NUM_OF_NAMES_TO_DISPLAY = 2;

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component {
  @service abilities;
  @service intl;
  @service store;

  get approvers() {
    let { isWorkflowStateStatus } = this.args;
    let approvers = isWorkflowStateStatus
      ? this.requestStateApprovers
      : this.approvalWorkflowApprovers;
    return approvers;
  }

  // This is the list of approvers displayed on the requests sidebars
  get requestStateApprovers() {
    let { approvers, value } = this.args.step;
    if (this.isCompleted) {
      return approvers.map(approver => approver.full_name);
    }
    return value.map(member => member.full_name);
  }

  // This is the list of approvers that we display on the approval-workflows page
  get approvalWorkflowApprovers() {
    let approversIds = this.args.step?.value || [];
    let members = this.store
      .peekAll('membership')
      .filter(({ id }) => approversIds.includes(id))
      .map(member => member.fullName);

    let roles = approversIds
      .filter(id => id === ROLES.ADMIN || id === ROLES.MANAGER)
      .map(role => {
        if (role === ROLES.ADMIN) return this.intl.t('approval-workflows.possible-approvers.admin');
        return this.intl.t('approval-workflows.possible-approvers.manager');
      });

    return [...members, ...roles];
  }

  get approverNames() {
    let approversCount = this.approvers.length || 0;
    let translationParameters = {
      count: approversCount,
      managerNameOne: this.approvers[0],
      managerNameTwo: this.approvers[1],
      remaining: approversCount - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (this.operationType === OPERATION_TYPES.ONE_OF) {
      return this.intl.t(
        'approval-workflows.ruleset.steps.approvers-list.equal-any',
        translationParameters
      );
    }

    return this.intl.t(
      'approval-workflows.ruleset.steps.approvers-list.equal-all',
      translationParameters
    );
  }

  get operationType() {
    return this.args.step?.operation;
  }

  get isCompleted() {
    return this.args.step?.status === STATUS.APPROVED || this.args.step?.status === STATUS.REJECTED;
  }

  get warningMessage() {
    if (!this.isCompleted && this.approvers.length === 0) {
      if (this.abilities.can('create approval-workflow')) {
        return this.intl.t('approval-workflows.requests.status.tooltip-approver');
      } else {
        return this.intl.t('approval-workflows.requests.status.tooltip-requester');
      }
    }
  }

  get approvalType() {
    if (this.isCompleted) {
      return this.statusApprovalText;
    } else {
      return this.operationTypeText;
    }
  }

  get operationTypeText() {
    switch (this.operationType) {
      case OPERATION_TYPES.ONE_OF:
        return this.intl.t('approval-workflows.ruleset.steps.equal-any');
      case OPERATION_TYPES.ALL_OF:
      default:
        return this.intl.t('approval-workflows.ruleset.steps.equal-all');
    }
  }

  get statusApprovalText() {
    if (this.args.step?.status === STATUS.APPROVED) {
      return this.intl.t('approval-workflows.requests.status.approved-by');
    } else if (this.args.step?.status === STATUS.REJECTED) {
      return this.intl.t('approval-workflows.requests.status.rejected-by');
    }
  }
}
