/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardTableItem extends Component {
  tag = Tag;

  get paymentLimit() {
    let { item } = this.args;
    if (item.cardLevel === CARD_LEVELS.FLASH) {
      return item.paymentLifespanLimit;
    }
    return item.paymentMonthlyLimit;
  }

  get paymentSpent() {
    let { item } = this.args;
    if (item.cardLevel === CARD_LEVELS.FLASH) {
      return item.paymentLifespanSpent;
    }
    return item.paymentMonthlySpent;
  }

  get itemIconName() {
    return `card-${this.args.item.design}`;
  }
  get itemIconUri() {
    return `/illustrations/cards/${this.itemIconName}.svg`;
  }
}
