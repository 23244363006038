export default {
  "row": "qU body-1",
  "active": "qj",
  "cell": "qW",
  "empty": "qu",
  "ellipsis": "ZS",
  "text-secondary": "Zc",
  "row-sidebar": "Zq",
  "cell-conditions": "ZZ",
  "animated": "ZR",
  "fadein-item": "ZQ",
  "animated-cell": "Ze",
  "pr-24": "ZB",
  "rule-with-error": "ZE",
  "icon": "Zd"
};
