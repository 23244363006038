export default {
  "dual-nav": "wc",
  "header": "wq",
  "expanded": "wZ",
  "collapsed": "wR",
  "responsive": "wQ",
  "overlay": "we",
  "visible": "wB",
  "footer": "wE"
};
