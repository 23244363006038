export default {
  "details": "zR",
  "top-details": "zQ",
  "name": "ze",
  "account-infobox": "zB",
  "row": "zE",
  "icon": "zd",
  "account-number": "zI",
  "currency": "zt",
  "activity-tag": "zM",
  "activity-icon": "zP",
  "edit-btn": "zl",
  "action-buttons-container": "zX"
};
