export default {
  "cell": "dO",
  "placeholder-line": "dm",
  "counterparty": "dp",
  "counterparty-content": "dx",
  "member": "dw",
  "method": "dN",
  "operation": "dv",
  "amount": "do",
  "remove-transaction": "dn",
  "empty": "di"
};
