export default {
  "container": "sw",
  "content": "sN",
  "subtitle": "sv",
  "switch": "so",
  "card-details": "sn",
  "asset-container": "si",
  "asset-container-padded": "sA",
  "asset": "sY",
  "right-container": "sh",
  "card-brand-title": "sf",
  "card-brand-badge": "sK",
  "free-subtitle": "sG",
  "benefit-container": "sr",
  "checkmark": "sb",
  "container-metal": "sy"
};
