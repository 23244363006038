export default {
  "connection-sidebar-header": "LN",
  "title": "Lv",
  "close": "Lo",
  "api-client": "Ln",
  "api-client-avatar": "Li",
  "api-client-name": "LA",
  "disclaimer": "LY",
  "expired": "Lh"
};
