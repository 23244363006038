export default {
  "hero-gradient": "Cq",
  "hero-wrapper": "CZ",
  "container-text": "CR",
  "title-with-badge": "CQ",
  "recycled-badge": "Ce",
  "hero-label": "CB",
  "hero-card": "CE",
  "svg-gradient": "Cd",
  "design": "CI",
  "design-image-container": "Ct",
  "design-image-overlay": "CM",
  "design-item": "CP",
  "hero-title": "Cl",
  "hero-subtitle": "CX",
  "metal-illustration": "CC",
  "cards": "Ck",
  "metal-illustration-old": "CJ",
  "rotating": "Cs",
  "verticaling": "CF",
  "card": "Cg",
  "circle": "CD"
};
