export default {
  "item": "NE",
  "qonto-pilot-nav-item": "Nd",
  "wrapper": "NI",
  "collapsed": "Nt",
  "label": "NM body-1",
  "icon-wrapper": "NP",
  "icon": "Nl",
  "key": "NX",
  "keys": "NC"
};
