export default {
  "header": "ay",
  "header__main": "aU",
  "note": "aj",
  "review-status": "aW",
  "reviewer-wrapper": "au",
  "reviewer": "HS",
  "decline-note": "Hc",
  "decline-note-title": "Hq",
  "status-dot": "HZ",
  "approved": "HR",
  "rejected": "HQ"
};
