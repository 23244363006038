export default {
  "row": "dA body-1",
  "cell": "dY",
  "counterparty": "dh",
  "counterparty-icon": "df",
  "avatar": "dK",
  "icon": "dG",
  "counterparty-content": "dr",
  "item-counterparty-name": "db",
  "counterparty-name": "dy body-1",
  "counterparty-status": "dU",
  "member": "dj",
  "method": "dW",
  "operation-date": "du",
  "amount": "IS",
  "remove-transaction": "Ic",
  "menu-item": "Iq",
  "remove": "IZ",
  "tooltip": "IR",
  "empty": "IQ"
};
