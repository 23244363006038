export default {
  "status-avatar": "gT mr-16",
  "row": "gz",
  "active": "ga",
  "animated": "gH",
  "fadein-item": "gO",
  "cell": "gm",
  "cell-content": "gp",
  "disabled": "gx",
  "not-disabled": "gw",
  "cell-amount": "gN",
  "ghost": "gv",
  "validated": "go",
  "status-pin": "gn",
  "item-emitter-name": "gi",
  "item-emitter-name-text": "gA"
};
