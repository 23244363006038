export default {
  "rule-card": "Ld",
  "rule-content": "LI",
  "rule-options": "Lt",
  "visible": "LM",
  "dropdown-btn": "LP",
  "delete-btn": "Ll",
  "btn--tertiary": "LX",
  "delete-rule-btn": "LC",
  "transaction-estimate": "Lk"
};
