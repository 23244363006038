export default {
  "header-cell": "qv",
  "empty": "qo",
  "header-type": "qn",
  "header-rules": "qi",
  "header-date": "qA",
  "header-action": "qY",
  "header-content": "qh caption-bold",
  "active": "qf",
  "row-sidebar": "qK",
  "hidden": "qG",
  "animated": "qr",
  "fadein-item": "qb",
  "animated-cell": "qy"
};
