export default {
  "card": "tu",
  "avatar": "MS",
  "owner": "Mc",
  "content": "Mq",
  "identity": "MZ",
  "fullname": "MR",
  "type": "MQ",
  "menu-container": "Me",
  "dropdown-menu": "MB",
  "menu-item": "ME body-2",
  "delete": "Md"
};
