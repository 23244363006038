export default {
  "row": "mO",
  "icon": "mm",
  "cell": "mp",
  "empty": "mx",
  "no-padding": "mw",
  "cell-content": "mN",
  "right": "mv",
  "cube": "mo",
  "row--sidebar-open": "mn",
  "status": "mi"
};
