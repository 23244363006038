export default {
  "aside": "Rz",
  "aside-section": "Ra",
  "aside-title": "RH caption-bold",
  "placeholder": "RO",
  "footer": "Rm",
  "cancel-button": "Rp",
  "attachment-date": "Rx body-2",
  "attachment-item": "Rw"
};
