export default {
  "tooltip": "wf",
  "wrapper": "wK",
  "collapsed": "wG",
  "label": "wr body-1",
  "selected": "wb",
  "icon-wrapper": "wy",
  "icon": "wU",
  "locked-icon": "wj",
  "notification-count": "wW",
  "fade-in": "wu",
  "responsive": "NS"
};
