import { template } from "@ember/template-compiler";
import { fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import themedAsset from '@qonto/ui-kit/helpers/themed-asset';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import Steps from 'qonto/components/timeline/steps';
import { OPERATION_TYPES } from 'qonto/constants/approval-workflow';
import Condition from './condition';
import OrderedSteps from './ordered-steps';
import styles from './ruleset.strict-module.css';
const ROLES = {
    ADMIN: 'admin',
    MANAGER: 'manager'
};
const MAX_STEPS = 5;
export default class ApprovalWorkflowFormRuleset extends Component {
    // State to track if approvers selector dropdown should remain open
    // The dropdown closes automatically because we generate a new list of option each time the user selects an option
    @tracked
    approversSelectorsTriggerMap = this.stepsByOrder.map((_1, orderIndex1)=>({
            index: orderIndex1,
            shouldOpen: false,
            lastSelection: null
        }));
    @service
    intl;
    @service
    store;
    @action
    updateTriggerMap(indexToRemainOpen1, lastSelection1) {
        this.approversSelectorsTriggerMap = this.stepsByOrder.map((_1, orderIndex1)=>({
                index: orderIndex1,
                shouldOpen: orderIndex1 === indexToRemainOpen1,
                lastSelection: lastSelection1
            }));
    }
    get approverRoles() {
        return {
            admin: this.intl.t('approval-workflows.roles.admin'),
            manager: this.intl.t('approval-workflows.roles.manager'),
            owner: this.intl.t('approval-workflows.roles.owner')
        };
    }
    #approverOptions(includeManagerAndAdmin1 = true) {
        let options1 = this.args.approverOptions || [];
        if (includeManagerAndAdmin1) {
            options1 = [
                {
                    description: this.intl.t('approval-workflows.possible-approvers.manager-description'),
                    id: ROLES.MANAGER,
                    name: this.intl.t('approval-workflows.possible-approvers.manager'),
                    role: ROLES.MANAGER
                },
                {
                    description: this.intl.t('approval-workflows.possible-approvers.admin-description'),
                    id: ROLES.ADMIN,
                    name: this.intl.t('approval-workflows.possible-approvers.admin'),
                    role: ROLES.ADMIN
                },
                ...options1
            ];
        }
        return options1.map((approver1)=>{
            return {
                description: approver1.description || this.approverRoles[approver1.role],
                key: approver1.id,
                role: approver1.role,
                value: approver1.name
            };
        });
    }
    get stepsByOrder() {
        return this.args.ruleset.steps.reduce(this.#groupByOrder, []).filter(Boolean).map(this.#addApproverOptions(this.#approverOptions.bind(this))).map(this.#disableApproverOptionsSelectedInOtherSteps);
    }
    #groupByOrder(acc1, item1) {
        if (!acc1[item1.order]) {
            acc1[item1.order] = [];
        }
        acc1[item1.order].push(item1);
        return acc1;
    }
    #addApproverOptions(approverOptionsFnc1) {
        return (steps1)=>{
            let isOperationAllOf1 = steps1.every((step1)=>step1.operation === OPERATION_TYPES.ALL_OF);
            steps1.approverOptions = approverOptionsFnc1(!isOperationAllOf1).map((approver1)=>{
                return {
                    disabled: this.#isAdminRoleSelectedAndNotRoleOption(approver1, steps1),
                    selected: this.#isAdminRoleSelectedOrStepSelected(approver1, steps1),
                    ...approver1
                };
            });
            return steps1;
        };
    }
    #disableApproverOptionsSelectedInOtherSteps(orderSteps1, _1, stepsByOrder1) {
        orderSteps1.approverOptions = orderSteps1.approverOptions.map((approverOption1)=>{
            return {
                ...approverOption1,
                disabled: approverOption1.disabled || stepsByOrder1.some((otherOrderSteps1)=>otherOrderSteps1[0].order !== orderSteps1[0].order && otherOrderSteps1.approverOptions.find(({ key: key1 })=>key1 === approverOption1.key)?.selected)
            };
        });
        return orderSteps1;
    }
    #isAdminRoleSelectedAndNotRoleOption(approver1, steps1) {
        return steps1.some((step1)=>this.#isAdminRoleSelected(approver1, step1) && approver1.key !== approver1.role);
    }
    #isAdminRoleSelectedOrStepSelected(approver1, steps1) {
        return steps1.some((step1)=>this.#isAdminRoleSelected(approver1, step1) || step1.value === approver1.key);
    }
    #isAdminRoleSelected(approver1, step1) {
        return step1.value === approver1.role && step1.value === ROLES.ADMIN;
    }
    @action
    onApproversChange(updateTriggerMap1, index1, orderSteps1, approverOptions1, selectedApproverOptions1, state1) {
        updateTriggerMap1(index1, state1.highlighted);
        selectedApproverOptions1 ||= []; // if clear button is pressed
        approverOptions1.forEach((approver1)=>{
            if (!approver1.selected && selectedApproverOptions1.includes(approver1)) {
                this.#addApprover(orderSteps1, approver1.key);
            } else if (approver1.selected && !selectedApproverOptions1.includes(approver1)) {
                this.#removeApprover(orderSteps1, approver1.key);
            }
        });
    }
    #addApprover(orderSteps1, approverId1) {
        let step1 = orderSteps1[0];
        let newStep1 = this.store.createRecord('approval-workflow/step', {
            field: Object.values(ROLES).includes(approverId1) ? 'role' : 'membership',
            operation: step1.operation,
            order: step1.order,
            value: approverId1
        });
        let { keep: keep1, remove: remove1 } = this.args.ruleset.steps.reduce(({ keep: keep1, remove: remove1 }, step1)=>{
            if ((step1.value === undefined && step1.order === newStep1.order) || // remove empty step
            this.#isAdminOptionAndStepIsAdminApprover(step1, this.args.approverOptions, approverId1)) {
                return {
                    keep: keep1,
                    remove: [
                        ...remove1,
                        step1
                    ]
                };
            } else {
                return {
                    keep: [
                        ...keep1,
                        step1
                    ],
                    remove: remove1
                };
            }
        }, {
            keep: [],
            remove: []
        });
        let sortedSteps1 = [
            ...keep1,
            newStep1
        ].sort((a1, b1)=>a1.order - b1.order);
        this.args.ruleset.steps = sortedSteps1;
        remove1.forEach((step1)=>step1.unloadRecord());
    }
    #removeApprover(orderSteps1, approverId1) {
        let step1 = orderSteps1[0];
        let { keep: keep1, remove: remove1 } = this.args.ruleset.steps.reduce(({ keep: keep1, remove: remove1 }, step1)=>{
            if (step1.value === approverId1 || this.#isAdminOptionAndStepIsAdminApprover(step1, this.args.approverOptions, approverId1)) {
                return {
                    keep: keep1,
                    remove: [
                        ...remove1,
                        step1
                    ]
                };
            } else {
                return {
                    keep: [
                        ...keep1,
                        step1
                    ],
                    remove: remove1
                };
            }
        }, {
            keep: [],
            remove: []
        });
        // keep atleast one empty step for each order
        let keepOrderSteps1 = keep1.reduce(this.#groupByOrder, [])[step1.order];
        if (keepOrderSteps1 === undefined || keepOrderSteps1.length === 0) {
            keep1 = [
                ...keep1,
                this.store.createRecord('approval-workflow/step', {
                    operation: step1.operation,
                    order: step1.order
                })
            ];
        }
        this.args.ruleset.steps = keep1;
        remove1.forEach((step1)=>step1.unloadRecord());
    }
    #isAdminOptionAndStepIsAdminApprover(step1, approverOptions1, approverId1) {
        if (approverId1 === ROLES.ADMIN) {
            let approverOption1 = approverOptions1.find((approver1)=>step1.value === approver1.id);
            return approverOption1?.role === ROLES.ADMIN;
        }
    }
    handleStepsAfterOperationTypeChange = (ruleset1, orderSteps1, order1)=>{
        let filteredSteps1 = ruleset1.steps.filter((step1)=>{
            if (step1.order === order1) {
                let isRole1 = step1.value === ROLES.ADMIN || step1.value === ROLES.MANAGER;
                return !isRole1;
            }
            return true;
        });
        // Checks which users were preselected from selecting the role
        // When switching to all_of, we keep the users that were preselected
        let disabledAndSelectedUsers1 = orderSteps1.approverOptions.filter((option1)=>option1.disabled && option1.selected);
        disabledAndSelectedUsers1 = disabledAndSelectedUsers1.map((user1)=>{
            return this.store.createRecord('approval-workflow/step', {
                operation: OPERATION_TYPES.ALL_OF,
                order: order1,
                value: user1.key,
                field: 'membership'
            });
        });
        // keep at least one empty step for each order
        // this prevents the case where all steps are removed
        let isOrderEmpty1 = filteredSteps1.every((step1)=>step1.order !== order1);
        if (!disabledAndSelectedUsers1.length && isOrderEmpty1) {
            disabledAndSelectedUsers1 = [
                this.store.createRecord('approval-workflow/step', {
                    operation: OPERATION_TYPES.ALL_OF,
                    order: order1
                })
            ];
        }
        ruleset1.steps = [
            ...filteredSteps1,
            ...disabledAndSelectedUsers1
        ];
    };
    handleOperationTypeChange = (ruleset1, orderSteps1, value1)=>{
        let { order: order1 } = orderSteps1[0];
        ruleset1.steps?.forEach((step1)=>{
            if (step1.order === order1) step1.operation = value1.key;
        });
        if (value1.key === OPERATION_TYPES.ALL_OF) {
            this.handleStepsAfterOperationTypeChange(ruleset1, orderSteps1, order1);
        }
    };
    get isConditionDisabled() {
        return this.args.index === 0;
    }
    get conditions() {
        return this.args.ruleset.conditions[0];
    }
    get conditionErrors() {
        return this.conditions.errors.get('value');
    }
    @action
    updateConditions(condition1, value1) {
        let conditionRecord1 = this.store.createRecord('approval-workflow/condition', {
            field: condition1.field,
            operation: condition1.operation,
            value: {
                value: value1,
                currency: condition1.value.currency
            }
        });
        this.args.ruleset.conditions = [
            conditionRecord1
        ];
    }
    get cannotAddNewStep() {
        return this.stepsByOrder?.length === MAX_STEPS;
    }
    @action
    addNewStep() {
        if (this.cannotAddNewStep) return;
        let { ruleset: ruleset1 } = this.args;
        let orders1 = ruleset1.steps.map((step1)=>step1.order);
        let highestOrder1 = Math.max(...orders1);
        let newStep1 = this.store.createRecord('approval-workflow/step');
        newStep1.set('order', highestOrder1 + 1);
        this.args.ruleset.steps = [
            ...this.args.ruleset.steps,
            newStep1
        ];
    }
    handleStepRemoval(ruleset1, orderSteps1) {
        let { order: order1 } = orderSteps1[0];
        let steps1 = ruleset1.steps?.filter((step1)=>step1.order !== order1);
        // Reorder the steps to keep the step.order starting from 1 consecutively
        let newOrder1 = 1;
        let lastStepOriginalOrder1;
        steps1 = steps1.map((step1, index1)=>{
            // First step always gets order 1
            // If the current order is the same as the previous, keep the adjusted order
            if (index1 !== 0 && step1.order !== lastStepOriginalOrder1) newOrder1++;
            lastStepOriginalOrder1 = step1.order;
            step1.order = newOrder1;
            return step1;
        });
        ruleset1.steps = steps1;
    }
    get isNotFirstRuleset() {
        return this.args.index > 0;
    }
    get shouldDisplayRulesetDeleteButton() {
        return this.isNotFirstRuleset;
    }
    get nextRulesetConditionAmount() {
        let { rulesets: rulesets1 } = this.args;
        return rulesets1?.at(this.args.index + 1)?.conditions[0]?.value?.value;
    }
    static{
        template(`
    <fieldset class={{styles.step-wrapper}} data-test-approval-workflow-form-ruleset ...attributes>
      <div
        class={{if this.isNotFirstRuleset styles.not-first-ruleset-header styles.ruleset-header}}
      >
        <Condition
          @disabled={{this.isConditionDisabled}}
          @condition={{this.conditions.value}}
          @onUpdate={{fn this.updateConditions this.conditions}}
          @validate={{@validate}}
          @index={{@index}}
          @errors={{this.conditionErrors}}
          @nextRulesetConditionAmount={{this.nextRulesetConditionAmount}}
          {{on 'focusout' @validate}}
        />
        {{#if this.shouldDisplayRulesetDeleteButton}}
          <button
            type='button'
            class='btn btn--icon-only btn--tertiary {{styles.delete-button}}'
            aria-label={{t 'a11y.buttons.delete-aria-label'}}
            data-test-approval-workflow-form-ruleset-remove-button
            {{on 'click' @removeRuleset}}
          >
            {{svgJar (themedAsset 'icon_cross_outlined') aria-hidden='true'}}
          </button>
        {{/if}}
      </div>
      <hr class={{styles.divider}} />
      <div class={{styles.ruleset-steps-container}}>
        <Steps data-test-approval-workflow-ruleset-steps as |Step|>
          {{#each this.stepsByOrder as |orderSteps index|}}
            <Step data-test-approval-workflow-ruleset-step={{index}}>
              <OrderedSteps
                @steps={{orderSteps}}
                @onUpdate={{@onUpdate}}
                @ruleset={{@ruleset}}
                @index={{index}}
                @numberOfOrders={{this.stepsByOrder.length}}
                @onApproversChange={{fn
                  this.onApproversChange
                  this.updateTriggerMap
                  index
                  orderSteps
                }}
                @handleOperationTypeChange={{fn this.handleOperationTypeChange @ruleset orderSteps}}
                @selectedOperation={{get orderSteps '0.operation'}}
                @handleStepRemoval={{fn this.handleStepRemoval @ruleset orderSteps}}
                @approversSelectorsTriggerMap={{this.approversSelectorsTriggerMap}}
                @updateTriggerMap={{this.updateTriggerMap}}
              />
            </Step>
          {{/each}}
          <Step @isLastStep={{true}} class={{styles.last-step-container}}>
            <button
              type='button'
              class='btn btn--tertiary mb-8 {{styles.add-approval-step}}'
              disabled={{this.cannotAddNewStep}}
              {{on 'click' this.addNewStep}}
              data-test-approval-workflow-ruleset-add-step-button
            >
              {{t 'approval-workflows.form.btn.add-step'}}
            </button>
          </Step>
        </Steps>
      </div>
    </fieldset>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
