export default {
  "envelope": "Hy",
  "payment-page": "HU",
  "header": "Hj",
  "title": "HW",
  "logo": "Hu",
  "detail": "OS",
  "dummy-field": "Oc",
  "input": "Oq",
  "input--select": "OZ",
  "input--readonly": "OR",
  "placeholder": "OQ",
  "columns": "Oe",
  "mandate": "OB",
  "mandate-title": "OE",
  "icon-chevron": "Od",
  "mandate-details": "OI",
  "checkbox": "Ot",
  "secure-note": "OM",
  "icon-lock": "OP",
  "cta": "Ol"
};
