export default {
  "shadow": "eU",
  "placeholder-line": "ej",
  "placeholder-square": "eW",
  "loading": "eu",
  "name": "BS title-4",
  "iban": "Bc caption",
  "transfers-button": "Bq",
  "cards-button": "BZ",
  "mandates-button": "BR",
  "menu-item": "BQ body-2",
  "close-account": "Be",
  "kyc-disclaimer": "BB"
};
