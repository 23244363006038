export default {
  "header-cell": "mJ",
  "empty": "ms",
  "right": "mF",
  "debitor": "mg",
  "frequency": "mD",
  "date": "mV",
  "status": "mL",
  "amount": "mT",
  "header-content": "mz caption-bold",
  "active": "ma",
  "row--sidebar-open": "mH"
};
