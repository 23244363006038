export default {
  "row": "pt",
  "icon": "pM",
  "cell": "pP",
  "empty": "pl",
  "no-padding": "pX",
  "cell-content": "pC",
  "right": "pk",
  "cube": "pJ"
};
