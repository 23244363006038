export default {
  "not-active": "Es",
  "title": "EF",
  "details": "Eg",
  "detail": "ED",
  "text-secondary": "EV",
  "text-primary-a": "EL",
  "amounts": "ET",
  "contents-container": "Ez",
  "contents": "Ea",
  "menu-item": "EH",
  "archive": "EO",
  "amount-settled": "Em",
  "shadow": "Ep",
  "name": "Ex title-4",
  "tooltip": "Ew"
};
