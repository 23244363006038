/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, Toggle } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, waitForProperty } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class EditFormComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  toggle = Toggle;

  @service toastFlashMessages;
  @service intl;
  @service sentry;

  @tracked submitButtonIsDisabled = false;
  @tracked showErrors = false;

  constructor() {
    super(...arguments);
    assert('You must pass an @submitTask', this.args.submitTask);
    assert('You must pass an @deleteTask', this.args.deleteTask);

    this.locale = this.intl.locale;
  }

  get periods() {
    return this.args.exercise.periods.filter(period => !period.isDeleted);
  }

  get globalAmountFieldErrorMessage() {
    if (this.args.exercise.isGlobal && this.showErrors) {
      return this.intl.t('team-budgets.creation.customize.error.global');
    }
  }

  @action shouldDisplayYear(period) {
    return (
      !this.args.exercise.isGlobal &&
      (this.periods.indexOf(period) === 0 || dayjs(period.startDate).month() === 0)
    );
  }

  @action displayYear(date) {
    return this.intl.formatDate(date, { year: 'numeric' });
  }

  @action
  disabled(period) {
    return dayjs().isAfter(period.endDate);
  }

  @action
  updateAmount(period, value) {
    this.submitButtonIsDisabled = false;
    next(() => {
      period.amountBudgetedValue = value;
    });
  }

  submitTask = dropTask(async () => {
    if (!this.submitButtonIsDisabled) {
      try {
        await this.args.exercise.validate();
        if (this.args.exercise.validations.isValid) {
          await this.args.submitTask.perform();
        } else {
          await this.handleError();
        }
      } catch (error) {
        await this.handleError(error);
      } finally {
        this.showErrors = !this.args.exercise.validations.attrs.totalBudgeted.isValid;
      }
    }
  });

  async handleError(error) {
    this.submitButtonIsDisabled = true;
    // any error that doesn't result in totatlBugeted validation failure isn't displayed in form so lets display a toast error.
    await waitForProperty(this.args.exercise.validations, 'isValidating', false);
    if (this.args.exercise.validations.attrs.totalBudgeted.isValid) {
      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }
}
